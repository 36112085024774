import { render, staticRenderFns } from "./CategoryMaterial.vue?vue&type=template&id=7cf6296a&scoped=true"
import script from "./CategoryMaterial.vue?vue&type=script&lang=js"
export * from "./CategoryMaterial.vue?vue&type=script&lang=js"
import style0 from "./CategoryMaterial.vue?vue&type=style&index=0&id=7cf6296a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf6296a",
  null
  
)

export default component.exports